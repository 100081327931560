body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body {
    background-color: #5955b3;
    box-sizing: border-box;
}

.body * {
    box-sizing: border-box;
}

.passport_item_wrapper {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.passport_item_wrapper p {
    width: 20%;
}